@import '/src/assets/scss/_variables.scss';

file-upload {
  background: transparent !important;
  outline-offset: 0 !important;
  border-radius: 4px !important;
  outline-color: rgba(76, 76, 76, 0.6) !important;

  &.ng-invalid.ng-touched {
    outline-color: $warn-main !important;
  }
}

file-upload-drop-zone {
  display: flex !important;
  align-items: center;
  .icon {
    min-height: 33px;
    min-width: 33px;
  }
}
